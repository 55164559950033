import { ConnectButton } from '@rainbow-me/rainbowkit';
import React, { useRef, useState } from 'react';
import logo from '../../assets/images/logo.png';
import { RxHamburgerMenu, RxCross2 } from 'react-icons/rx';
import styles from './Navbar.module.css';
import downArrow from '../../assets/images/downArrow.png';
import { BsChevronDown } from 'react-icons/bs';
import useNotyf from '../../hooks/useNotyf';

const options = [
  { value: '', label: 'Coming Soon' },
  { value: 'pledge', label: 'Pledge' },
  { value: 'nft', label: 'Nft' },
];

const Navbar = ({ scrollToSection }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);
  const [openOptions, setOpenOptions] = useState(false);
  const notyf = useNotyf();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={styles.container}>
      <ul className={styles.logo}>
        <li className={styles.mobile_icons} onClick={handleMobileMenuToggle}>
          <RxHamburgerMenu />
        </li>
        <li>
          <img src={logo} alt="logo" />
        </li>
        <li>XPenguin</li>
      </ul>

      {/* Menu  */}
      <ul className={`${styles.menu} ${isMobileMenuOpen ? styles.open : styles.close}`}>
        <li onClick={handleMobileMenuToggle} className={styles.mobile_icons}>
          <RxCross2 />
        </li>
        <li onClick={() => scrollToSection('home')}>Home</li>
        <li onClick={() => scrollToSection('tokenomics')}>About</li>
        <li onClick={() => scrollToSection('tokenomics')}>Tokenomics</li>
        <li onClick={() => scrollToSection('roadmap')}>Roadmap</li>

      
      </ul>  
    </nav>
  );
};

export default Navbar;
