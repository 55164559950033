import React, { useEffect, useRef, useState } from 'react';
import styles from './Roadmap.module.css';
import styles2 from '../Ido/Ido.module.css';
import phase1 from '../../assets/images/phase1.png';
import phase2 from '../../assets/images/phase2.png';
import phase3 from '../../assets/images/phase3.png';
import phase4 from '../../assets/images/phase4.png';

const roadmap = [
  { phase: 'Phase 1', text: 'Awareness Building', src: phase1 },
  { phase: 'Phase 2', text: 'Community Building', src: phase2 },
  { phase: 'Phase 3', text: 'Token Sale and Exchange Listing', src: phase3 },
  { phase: 'Phase 4', text: 'Continuous Development and Partnerships', src: phase4 }
];

const Roadmap = () => {
  const [cards, setCards] = useState(roadmap);

  useEffect(() => {
    // Set an interval to scroll automatically
    const interval = setInterval(() => {
      setCards((prev) => {
        const first = prev.shift();
        const new_coins = [...prev, first];
        return new_coins;
      });
    }, 3000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <section className={[styles2.container, styles.container].join(' ')} id="roadmap">
      <h1 className="heading">Roadmap</h1>

      <div className={styles.roadmap}>
        {cards.map((item) => (
          <div className={styles.card} key={item.phase}>
            <img src={item.src} alt="" className={styles.image} />
            <span>
              <h4>{item.phase}</h4>
              <p>{item.text}</p>
            </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
