import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [tokenInfo, setTokenInfo] = useState({
    name: "xPenguin",
    symbol: "xPenguin",
    totalSupply: "1",
    decimals: "18",
  });
  return (
    <AppContext.Provider value={{ tokenInfo, setTokenInfo }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
