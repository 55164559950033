import React, { useState } from 'react';
import samurai from '../../assets/images/intro_samurai.png';
import styles from './Intro.module.css';
import { BsChevronDown } from 'react-icons/bs';
import downArrow from '../../assets/images/downArrow.png';

const options = [
  { value: '', label: 'Documents' },
  { value: 'whitepaper', label: 'Whitepaper', link: 'https://thematrixlabs.gitbook.io/whitepaper-savior/' },
  { value: 'audit', label: 'Audit', link: 'https://app.solidproof.io/projects/savior' },
];

const Intro = () => {
  const [readMore, setReadMore] = useState(false);
  const [selected, setSelected] = useState(options[0]);
  const [openOptions, setOpenOptions] = useState(false);

  return (
    <section className={styles.container}>
      <div data-aos="fade-up">
        <h1 className="heading">Introduction</h1>
        <img src={samurai} alt="samurai" />
        <p>
          <br />
          Welcome to the world of xPenguin Token - where penguins lead the crypto revolution! 
          <br />
          Inspired by the enchanting allure of penguins and echoed by Elon Musk’s compelling penguin imagery, xPenguin is more than just a token.
          <br />
          {!readMore && <span onClick={() => setReadMore(true)}>read more...</span>}
          {readMore && (
            <p className={styles['read-more']}>
              it’s a celebration of the strength and freedom of these incredible creatures. Join us in making history with xPenguin Token, as we embark on this exciting journey to infuse the crypto world with the valor and spirit of penguins. 
              <br />
              <br />
              Be a part of the change and help shape a future where penguins rule the crypto universe. Dive in now and let the adventure begin with xPenguin!
            </p>
          )}
          {readMore && <span onClick={() => setReadMore(false)}>read less</span>}
        </p>
        {/* <select>
          <option value="">Documents</option>
          <option value="whitepaper">Whitepaper</option>
          <option value="audit">Audit</option>
          <option value="kyc">Kyc</option>
        </select> */}

      </div>
      <div>
        <img src={samurai} alt="samurai" />
      </div>
    </section>
  );
};

export default Intro;
