import React, { useState } from 'react';
import styles from './Footer.module.css';
import footer_logo from '../../assets/images/footer_logo.png';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { BsChevronDown } from 'react-icons/bs';
import useNotyf from '../../hooks/useNotyf';
import AliceCarousel from "react-alice-carousel";
import bnbIcon from "../../assets/images/icon-bnb.svg";
import pinksaleIcon from "../../assets/images/icon-pinksale.svg";
import coinmarketcapIcon from "../../assets/images/icon-coinmarketcap.svg";
import dexview_logo from "../../assets/images/icon-bnb.svg";
import pancakeswapIcon from "../../assets/images/icon-pancakeswap.svg";
import coinsultIcon from "../../assets/images/icon-coinsult.svg";
import "react-alice-carousel/lib/alice-carousel.css";
import { coinMarketCapLink, coinsultLink, dexViewLink, officialMail, pancakeSwapLink, pinksaleLink, telegramLink, twitterLink } from '../../utils/sociallinks';


const options = [
  { value: '', label: 'Coming Soon' },
  { value: 'pledge', label: 'Pledge' },
  { value: 'nft', label: 'Nft' },
];

const items = [
  <img src={bnbIcon} className={styles.bnbIcon} alt="binance-icon" onDragStart={handleDragStart} />,

  <img src={pinksaleIcon} onClick={() => redirectToNewTab(pinksaleLink)} alt="pinksale-icon" onDragStart={handleDragStart} />,

  <img src={coinmarketcapIcon} onClick={()=>redirectToNewTab(coinMarketCapLink)} className={styles.coinmarketcapIcon} alt="coinmarketcap-icon" onDragStart={handleDragStart} />,

  <img src={pancakeswapIcon} onClick={() => redirectToNewTab(pancakeSwapLink)} className="pancakeswapIcon" alt="pancakeswap-icon" onDragStart={handleDragStart} />,
  
  <img src={dexview_logo} onClick={() => redirectToNewTab(dexViewLink)} className="dexviewIcon" alt="dexview-icon" onDragStart={handleDragStart} />,

  <img src={coinsultIcon} onClick={() => redirectToNewTab(coinsultLink)} className="coinsultIcon" alt="coinsult-icon" onDragStart={handleDragStart} />,
];

const responsive = {
  0: { items: 1 },
  600: { items: 2 },
  1200: { items: 3 },
  1500: { items: 5 },
};

function redirectToNewTab(url) {
  window.open(url, "_blank");
}

const handleDragStart = (e) => e.preventDefault();


const Footer = ({ scrollToSection }) => {
  const [selected, setSelected] = useState(options[0]);
  const [openOptions, setOpenOptions] = useState(false);
  const notyf = useNotyf();

  const [iscopied, setIsCopied] = useState(false);



  return (
    <footer className={styles.container}>
      <AliceCarousel items={items} responsive={responsive} autoPlay autoPlayInterval={1000} animationDuration={1000} disableButtonsControls disableDotsControls mouseTracking infinite animationType="fadeout" paddingLeft={24} paddingRight={24} itemPosition="center" stagePadding={{ paddingLeft: 60, paddingRight: 60 }} slideToIndex={0} dotsDisabled buttonsDisabled />
      <img src={footer_logo} alt="" />
      <h3>SITEMAP</h3>

      <div className={styles.sections}>
        <p onClick={() => scrollToSection('home')}>Home</p>
        <p onClick={() => scrollToSection('tokenomics')}>About Us</p>
        <p onClick={() => scrollToSection('tokenomics')}>Tokenomics</p>
        <p onClick={() => scrollToSection('roadmap')}>Roadmap</p>

        {/* <select value={''}>
          <option value="" selected>
            Coming Soon
          </option>
          <option value="pledge">Pledge</option>
          <option value="nft">Nft</option>
        </select> */}

      </div>

      <div className={styles.socials}>
        <AiOutlineTwitter fill="#84BCCE" size={25} onClick={() => redirectToNewTab(twitterLink)} />
        <FaTelegramPlane fill="#84BCCE" size={25} onClick={() => redirectToNewTab(telegramLink)} />
        <IoIosMail fill="#84BCCE" size={25} onClick={() => redirectToNewTab(officialMail)} />
      </div>
    </footer>
  );
};

export default Footer;
