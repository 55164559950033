import React, { useContext, useState } from 'react';
import tokenomics from '../../assets/images/tokenomics.png';
import tokenomics_mobile from '../../assets/images/tokenomics.png';
import styles2 from '../Ido/Ido.module.css';
import styles from './Tokenomics.module.css';
import { AppContext } from '../../context/AppContext';
import { formatStringToTwoDecimals, convertToTrillionRepresentation } from '../../utils/format';
import { tokenAddress } from '../../utils/sociallinks';
import copyimg from '../../assets/images/copy.png';
import copied from '../../assets/images/copied.png';

const Tokenomics = () => {
  const { tokenInfo } = useContext(AppContext);
  const [iscopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(tokenAddress);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <section className={[styles2.container, styles.container].join(' ')} id="tokenomics">
      <h1 className="heading">Tokenomics</h1>

      <div className={[styles2.wrapper, styles.wrapper].join(' ')}>
        <div className={[styles2.tokenInfo, styles.tokenInfo].join(' ')} data-aos="fade-up-right">
          <span>Token Details</span>

          <p>
            Name <span>{tokenInfo.name}</span>
          </p>
          <p>
            Symbol <span>{tokenInfo.symbol}</span>
          </p>
          <p>
            Total Supply <span>{tokenInfo.totalSupply} Trillion</span>
          </p>
          <p>
            Decimals <span>{tokenInfo.decimals}</span>
          </p>
        </div>
        <div>
          <img src={tokenomics} alt="tokenomics" className={styles.tokenomics} />
          <img src={tokenomics_mobile} alt="tokenomics_mobile" className={styles.tokenomics_mobile} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ margin: "auto" }} className={[styles2.tokenInfo, styles.tokenInfo, styles.tokenaddress].join(' ')} data-aos="fade-up">
        <span>Token Address</span>

        <p className={styles.tokenaddressline}>
          Token Address <span>{tokenAddress}&nbsp;&nbsp;{iscopied ? <img src={copied} alt="" srcset="" /> : <img onClick={handleCopyClick} src={copyimg} alt="" />}</span>
        </p>
      </div>
    </section>
  );
};

export default Tokenomics;
