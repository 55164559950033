let twitterLink = "https://twitter.com/xPenguin_BSC";

let telegramLink = "https://t.me/xPenguin_BSC";

let pancakeSwapLink = "https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x25b6d0641a83550e95545d45fef647a3ab6a8ed9";

let dexViewLink = "https://www.dexview.com/bsc/0x25b6d0641a83550e95545d45fef647a3ab6a8ed9";

let bscScanLink = "https://bscscan.com/token/0x25b6d0641a83550e95545d45fef647a3ab6a8ed9";

let pinksaleLink = "";

let coinMarketCapLink = "";

let coinGeckoLink = "";

let officialMail = "";

let coinsultLink = "";

let tokenAddress = "0x25B6D0641A83550E95545D45fEf647A3aB6a8eD9";

function redirectToNewTab(url) {
  window.open(url, "_blank");
}

export { twitterLink, telegramLink, pancakeSwapLink, dexViewLink, bscScanLink, pinksaleLink, coinMarketCapLink, coinGeckoLink, redirectToNewTab, officialMail,coinsultLink, tokenAddress };
