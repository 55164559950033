import { useEffect } from 'react';
import { Notyf } from 'notyf';

const useNotyf = () => {
  const notyf = new Notyf({
    duration: 5000,
    position: { x: 'right', y: 'top' },
    dismissible: true,
    types: [
      {
        type: 'success',
        background: '#f05733',
      },
      {
        type: 'error',
        background: 'gray',
      },
      // Add more custom types if needed
    ],
  });

  useEffect(() => {
    // Clean up the Notyf instance when the component unmounts
    return () => {
      notyf.dismissAll();
    };
  }, []);

  return notyf;
};

export default useNotyf;
